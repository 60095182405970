<template>
    <div>
      <div style="margin: -45px -24px 0px">
        <a-card :bordered="false">
          <DescriptionList>
            <div style="text-align: right" class="table-operator">
              <label class="detailTop-edit">
                <img :src="tenant" class="icon" />&nbsp;&nbsp;
                {{ detail.name }}&nbsp;&nbsp;
                <div :class="this.changeImgbg()">
                  {{ this.detail.coopStatus }}
                </div>
              </label>
              <a-button type="primary" @click="onEdit">编辑</a-button>
            </div>
            <div class="title30" style="margin-left: 52px">
              <Description term="所属品牌"
                ><label>{{ detail.brandName }}</label>
              </Description>
              <Description term="客户类型"
                ><label>{{ detail.type }}</label>
              </Description>
              <Description term="客户等级"
                ><label>{{ detail.level }}</label>
              </Description>
              <Description term="经营品类"
                ><label>{{ detail.cuisineType }}</label>
              </Description>
              <Description term="销售负责人"
                ><label>{{ detail.saleManager }}</label>
              </Description>
              <Description term="客户运营"
                ><label>{{ detail.operator }}</label>
              </Description>
              <Description term="签约档口"
                ><label>{{ detail.kitchenName }}</label>
              </Description>
              <br />
              <Description term="客户ID"
                ><label>{{ detail.code }}</label>
              </Description>
            </div>
          </DescriptionList>
        </a-card>
      </div>
      <div style="margin-bottom: 24px">
        <a-card
          style="margin: -24px -24px 24px"
          :bordered="false"
          :tabList="tabListNoTitle"
          :activeTabKey="noTitleKey"
          @tabChange="key => onTabChange(key, 'noTitleKey')"
        >
        </a-card>
        <div style="margin-left: 16px">
          <a-card
            v-if="noTitleKey === 'baseDetail'"
            :bordered="false"
            style="margin-left: -16px"
          >
            <DescriptionList title="联系方式" size="large">
              <div style="margin-top: 16px">
                <Description term="账户名称"
                  ><label>{{ detail.bankName }}</label></Description
                >
                <Description term="客户主体"
                  ><label>{{ detail.tenantEntity }}</label></Description
                >
                <Description term="开户银行"
                  ><label>{{ detail.bankBranch }}</label></Description
                >
                <Description term="银行账户"
                  ><label>{{ detail.bankAccount }}</label></Description
                >
              </div>
            </DescriptionList>
          </a-card>
          <a-card v-if="noTitleKey === 'rentDetail'" :bordered="false">
          </a-card>
          <a-card v-if="noTitleKey === 'licenceDetail'" :bordered="false">
          </a-card>
          <a-card v-if="noTitleKey === 'costDetail'" :bordered="false">
          </a-card>
        </div>
      </div>
      <div style="margin-bottom: 24px">
        <a-card :bordered="false">
          <DescriptionList size="large">
            <a-card :bordered="false" style="margin-top: -24px">
              <div>
                <label class="listTitle">客户信息</label>
                <a-form
                  layout="inline"
                  style="margin-bottom: 18px; text-align: right"
                >
                  <a-button
                    v-if="this.rolenewHasRight === true"
                    icon="plus"
                    type="primary"
                    @click="addStaff"
                    >新增人员</a-button
                  >
                </a-form>
              </div>
              <a-table
                rowKey="id"
                :pagination="paginationStaff"
                :columns="columnsStaff"
                :dataSource="dataStaff"
                :scroll="{ x: 800 }"
                @change="changePageSizeStaff"
              >
                <span slot="serial" slot-scope="text, record, index">
                  {{ index + 1 }}
                </span>
                <span slot="action" slot-scope="text, record" class="blueColor">
                  <template>
                    <a
                      v-if="roleeditHasRight === true"
                      @click="handlestaffEdit(record)"
                      >编辑</a
                    >&nbsp;&nbsp;
                    <a
                      v-if="roleeditHasRight === true"
                      @click="staffDelete(record)"
                      >删除</a
                    >
                  </template>
                </span>
              </a-table>
            </a-card>
          </DescriptionList>
        </a-card>
      </div>
      <a-modal
        title="新增联系人"
        :width="640"
        :visible="newvisible"
        :confirmLoading="confirmLoading"
        destroyOnClose
        @ok="handleSubmitStaff"
        @cancel="handleCancel"
      >
        <a-spin :spinning="confirmLoading">
          <a-form :form="form">
            <a-form-item
              label="姓名"
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
            >
              <a-input
                v-decorator="[
                  'name',
                  { rules: [{ required: true, message: '请输入' }] }
                ]"
                placeholder="请输入"
              />
            </a-form-item>
            <a-form-item
              label="职位"
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
            >
              <a-input
                v-decorator="[
                  'positionName',
                  { rules: [{ required: false, message: '请输入！' }] }
                ]"
                placeholder="请输入"
              >
                <!-- <a-select-option v-for="item in shopPosition" :key="item.id">
                  {{ item.name }}
                </a-select-option> -->
              </a-input>
            </a-form-item>
            <a-form-item
              label="手机号"
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
            >
              <a-input
                v-decorator="[
                  'phone',
                  { rules: [{ required: true, message: '请输入' }] }
                ]"
                placeholder="请输入"
              />
            </a-form-item>
            <a-form-item
              label="邮箱"
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
            >
              <a-input
                v-decorator="[
                  'email',
                  { rules: [{ required: false, message: '请输入' }] }
                ]"
                placeholder="请输入"
              />
            </a-form-item>
          </a-form>
        </a-spin>
      </a-modal>
      <a-modal
        title="编辑"
        :width="640"
        :visible="editvisible"
        :confirmLoading="confirmLoading"
        destroyOnClose
        @ok="handleEditStaff"
        @cancel="handleCancel"
      >
        <a-spin :spinning="confirmLoading">
          <a-form :form="form">
            <a-form-item
              label="姓名"
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
            >
              <a-input
                v-decorator="[
                  'name',
                  {
                    rules: [{ required: true, message: '请输入' }],
                    initialValue: this.record.name
                  }
                ]"
                placeholder="请输入"
              />
            </a-form-item>
            <a-form-item
              label="职位"
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
            >
              <a-input
                v-decorator="[
                  'positionName',
                  {
                    rules: [{ required: true, message: '请输入' }],
                    initialValue: this.record.positionName
                  }
                ]"
                placeholder="请输入"
              />
              <!-- <a-select
                v-decorator="[
                  'positionId',
                  {
                    rules: [{ required: true, message: '请输入' }],
                    initialValue: this.record.positionName
                  }
                ]"
                placeholder="请选择"
              >
                <a-select-option v-for="item in shopPosition" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select> -->
            </a-form-item>
            <a-form-item
              label="联系方式"
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
            >
              <a-input
                v-decorator="[
                  'phone',
                  {
                    rules: [{ required: true, message: '请输入' }],
                    initialValue: this.record.phone
                  }
                ]"
                placeholder="请输入"
              />
            </a-form-item>
            <a-form-item
              label="邮箱"
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
            >
              <a-input
                v-decorator="[
                  'email',
                  {
                    rules: [{ required: true, message: '请输入' }],
                    initialValue: this.record.email
                  }
                ]"
                placeholder="请输入"
              />
            </a-form-item>
          </a-form>
        </a-spin>
      </a-modal>
      <div style="margin-bottom: 24px">
        <a-card :bordered="false">
          <DescriptionList size="large">
            <a-card :bordered="false" style="margin-top: -24px">
              <div>
                <label class="listTitle">权限管理</label>
                <a-form
                  layout="inline"
                  style="margin-bottom: 18px; text-align: right"
                >
                  <a-button
                    v-if="this.authnewHasRight === true"
                    icon="plus"
                    type="primary"
                    @click="newEquipment"
                    >新增</a-button
                  >
                </a-form>
              </div>

              <a-table
                rowKey="id"
                :pagination="pagination"
                :columns="equipcolumns"
                :dataSource="devicedata"
                :scroll="{ x: 700 }"
              >
                <span slot="serial" slot-scope="text, record, index">
                  {{ index + 1 }}
                </span>
                <span slot="positionId" slot-scope="text, record">
                  <template>
                    <span>{{ record.positionId | showPositionId }}</span>
                  </template>
                </span>
                <span slot="action" slot-scope="text, record" class="blueColor">
                  <template>
                    <a
                      v-if="autheditHasRight === true"
                      @click="handleEditEqu(record)"
                      >编辑</a
                    >&nbsp;&nbsp;
                    <a
                      v-if="autheditHasRight === true"
                      @click="handleDeleteEqu(record)"
                      >删除</a
                    >
                  </template>
                </span>
              </a-table>
            </a-card>
          </DescriptionList>
        </a-card>
      </div>
      <a-modal
        title="新建权限"
        :width="640"
        :visible="newequipment"
        :confirmLoading="confirmLoading"
        destroyOnClose
        @ok="newequipmentSubmit"
        @cancel="handleCancel"
      >
        <a-spin :spinning="confirmLoading">
          <a-form :form="form">
            <a-form-item
              label="姓名"
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
            >
              <a-input
                v-decorator="[
                  'name',
                  { rules: [{ required: true, message: '请输入' }] }
                ]"
                placeholder="请输入"
              />
            </a-form-item>
            <a-form-item
              label="职位"
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
            >
              <a-select
                v-decorator="[
                  'positionId',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请选择'
                      }
                    ]
                  }
                ]"
                placeholder="请选择"
              >
                <a-select-option v-for="item in positionList" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item
              label="店铺名称"
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
            >
              <a-select
                v-decorator="[
                  'storeId',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请选择'
                      }
                    ]
                  }
                ]"
                placeholder="请选择"
              >
                <a-select-option v-for="item in storeList" :key="item.id">
                  {{ item.optionText }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item
              label="手机号"
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
            >
              <a-input
                v-decorator="[
                  'phone',
                  { rules: [{ required: true, message: '请输入' }] }
                ]"
                placeholder="请输入"
              />
            </a-form-item>
            <a-form-item
              label="备注"
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
            >
              <a-input
                v-decorator="[
                  'remark',
                  { rules: [{ required: false, message: '请输入' }] }
                ]"
                placeholder="请输入"
              />
            </a-form-item>
          </a-form>
        </a-spin>
      </a-modal>
      <!-- 权限编辑 -->
      <a-modal
        title="编辑权限"
        :width="640"
        :visible="changeEquipment"
        :confirmLoading="confirmLoading"
        destroyOnClose
        @ok="editEquipmentSubmit"
        @cancel="handleCancel"
      >
        <a-spin :spinning="confirmLoading">
          <a-form :form="form">
            <a-form-item
              label="姓名"
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
            >
              <a-input
                v-decorator="[
                  'name',
                  {
                    rules: [{ required: true, message: '请输入' }],
                    initialValue: this.myEditEqu.name
                  }
                ]"
                placeholder="请输入"
              />
            </a-form-item>
            <a-form-item
              label="职位"
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
            >
              <a-select
                v-decorator="[
                  'positionId',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请选择'
                      }
                    ],
                    initialValue: this.myEditEqu.positionId
                  }
                ]"
                placeholder="请选择"
              >
                <a-select-option v-for="item in positionList" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item
              label="店铺名称"
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
            >
              <a-select
                v-decorator="[
                  'storeId',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请选择'
                      }
                    ],
                    initialValue: this.myEditEqu.storeId
                  }
                ]"
                placeholder="请选择"
              >
                <a-select-option v-for="item in storeList" :key="item.id">
                  {{ item.optionText }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item
              label="手机号"
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
            >
              <a-input
                v-decorator="[
                  'phone',
                  {
                    rules: [{ required: true, message: '请输入' }],
                    initialValue: this.myEditEqu.phone
                  }
                ]"
                placeholder="请输入"
              />
            </a-form-item>
            <a-form-item
              label="备注"
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
            >
              <a-input
                v-decorator="[
                  'remark',
                  {
                    rules: [{ required: false, message: '请输入' }],
                    initialValue: this.myEditEqu.remark
                  }
                ]"
                placeholder="请输入"
              />
            </a-form-item>
          </a-form>
        </a-spin>
      </a-modal>
      <div style="margin-bottom: 24px">
        <a-card :bordered="false">
          <DescriptionList size="large">
            <a-card :bordered="false">
              <div
                class="table-operator"
                style="margin-top: -24px;margin-bottom: 24px"
              >
                <label class="listTitle">合同信息</label>
                <a-form
                  v-if="this.searchconHasRight === true"
                  layout="inline"
                  style="text-align: right"
                >
                  <a-form-item label="所属城市">
                    <a-input
                      v-model="queryParam.cityName"
                      @pressEnter="selectContract"
                      placeholder="请输入"
                    />
                  </a-form-item>
                  <a-form-item label="厨房品牌">
                    <a-select
                      style="width: 170px"
                      v-model="queryParam.brandName"
                      @change="selectContract"
                      allowClear
                      placeholder="请输入"
                    >
                      <a-select-option
                        v-for="item in brandNameList"
                        :key="item.id"
                        >{{ item.name }}</a-select-option
                      >
                    </a-select>
                  </a-form-item>
                  <a-form-item label="所属门店">
                    <a-input
                      v-model="queryParam.facilityName"
                      @pressEnter="selectContract"
                      placeholder="请输入"
                    />
                  </a-form-item>
                  <!-- <a-button type="primary" @click="selectContract"
                    >搜索</a-button
                  > -->
                </a-form>
              </div>
              <a-table
                rowKey="id"
                :pagination="paginationcontractData"
                :columns="contractColumns"
                :dataSource="contractData"
                :scroll="{ x: 800 }"
                @change="changePageSizecontractData"
              >
                <span slot="action" slot-scope="text, record" class="blueColor">
                  <template>
                    <a @click="handleDetails(record)">{{
                      record.contractCode
                    }}</a
                    >&nbsp;&nbsp;
                  </template>
                </span>
                <span slot="serial" slot-scope="text, record, index">
                  {{ index + 1 }}
                </span>
              </a-table>
            </a-card>
          </DescriptionList>
        </a-card>
      </div>
      <div>
        <a-card :bordered="false">
          <DescriptionList size="large">
            <a-card :bordered="false">
              <div
                class="table-operator"
                style="margin-top: -24px;margin-bottom: 24px"
              >
                <label class="listTitle">账单信息</label>
                <a-form
                  v-if="this.searchbillHasRight === true"
                  layout="inline"
                  style="text-align: right"
                >
                  <a-form-item label="所属城市">
                    <a-input
                      v-model="finqueryParam.cityName"
                      @pressEnter="selectBill"
                      placeholder="请输入"
                    />
                  </a-form-item>
                  <a-form-item label="所属门店">
                    <a-input
                      v-model="finqueryParam.facilityName"
                      @pressEnter="selectBill"
                      placeholder="请输入"
                    />
                  </a-form-item>
                  <a-form-item label="账户周期">
                    <a-input
                      v-model="finqueryParam.cycleStartDateLg"
                      @pressEnter="selectBill"
                      placeholder="请输入"
                    />
                  </a-form-item>
                  <!-- <a-button type="primary" @click="selectBill">搜索</a-button> -->
                </a-form>
              </div>
              <a-table
                rowKey="id"
                :pagination="paginationfin"
                :columns="fincolumns"
                :dataSource="findata"
                :scroll="{ x: 1500 }"
                @change="changePageSizefin"
              >
                <span slot="serial" slot-scope="text, record, index">
                  {{ index + 1 }}
                </span>
                <span slot="cycleStartDateLg" slot-scope="text, record">
                  {{ record.cycleStartDateLg }} — {{ record.cycleEndDateLg }}
                </span>
                <span slot="payCycleStartDateLg" slot-scope="text, record">
                  {{ record.payCycleStartDateLg }} —
                  {{ record.payCycleEndDateLg }}
                </span>
                <span slot="action" slot-scope="text, record" class="blueColor">
                  <template>
                    <a @click="handleDetails(record)">查看</a>&nbsp;&nbsp;
                    <a @click="handleEdit(record)">编辑</a>&nbsp;&nbsp;
                    <a @click="handleActive">操作日志</a>
                  </template>
                </span>
              </a-table>
            </a-card>
          </DescriptionList>
        </a-card>
      </div>
    </div>
</template>

<script>
import tenant from "@/assets/tenant.png";
import DescriptionList from "@/components/DescriptionList";
const { Description } = DescriptionList;
import axios from "axios";
  
import { modal, notification } from "ant-design-vue";
import moment from "moment";
import Bus from "@/bus";
import { defaultPagination } from "@/utils/pagination";
import { mapGetters } from "vuex";
import { hasPermissions } from "../../../utils/auth";
import { OrganizationActions } from "../../../utils/actions";

export default {
  name: "roleListDetail",
  components: {
    DescriptionList,
    Description,
  },
  data() {
    return {
      roleListDetailId: this.$route.query.roleListDetailId,
      tenant,
      // 职位
      positionList: [
        { id: 1, name: "管理员" },
        { id: 2, name: "店长" }
      ],
      // 店铺列表
      storeList: [],
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      },
      myEditEqu: {}, //权限编辑时拿到的值
      newequipment: false,
      changeEquipment: false,
      confirmLoading: false,
      newvisible: false,
      editDetail: false,
      form: this.$form.createForm(this),
      contractData: [],
      devicedata: [],
      oneLevelDpt: {
        id: 0,
        level: 0,
        parentId: 0,
        parentName: "",
        staffDTO: {}
      },
      //厨房品牌
      brandNameList: [
        { id: 1, name: "吉刻联盟" },
        { id: 2, name: "闪电厨房" },
        { id: 3, name: "食秀坊" },
        { id: 4, name: "橘猫" },
        { id: 5, name: "海星微厨" },
        { id: 6, name: "美食门" }
      ],
      //职位
      shopPosition: [
        {
          id: 1,
          name: "店长"
        },
        {
          id: 2,
          name: "门店店助"
        },
        {
          id: 3,
          name: "门店区域经理"
        },
        {
          id: 4,
          name: "工程部负责人"
        },
        {
          id: 5,
          name: "管理员"
        }
      ],
      equipcolumns: [
        // {
        //   title: "设备名称",
        //   dataIndex: "name",
        //   key: "name",
        //   fixed: "left"
        // },
        // {
        //   title: "设备数量",
        //   dataIndex: "num",
        //   key: "num"
        // },
        // {
        //   title: "电压/个设备",
        //   dataIndex: "power",
        //   key: "power"
        // },
        // {
        //   title: "功率/每个设备",
        //   dataIndex: "voltage",
        //   key: "voltage"
        // },
        // {
        //   title: "燃气",
        //   dataIndex: "gasQuantity",
        //   key: "gasQuantity"
        // },
        // {
        //   title: "备注",
        //   dataIndex: "remark",
        //   key: "remark"
        // },
        {
          title: "姓名",
          dataIndex: "name",
          key: "name",
          fixed: "left"
        },
        {
          title: "职位",
          dataIndex: "positionId",
          key: "positionId",
          scopedSlots: { customRender: "positionId" }
        },
        {
          title: "手机号",
          dataIndex: "phone",
          key: "phone"
        },
        {
          title: "店铺名称",
          dataIndex: "storeName",
          key: "storeName"
        },
        {
          title: "操作",
          // dataIndex: "action",
          key: "action",
          scopedSlots: { customRender: "action" },
          fixed: "right",
          width: 150
        }
      ],
      dataStaff: [],
      columnsStaff: [
        {
          title: "姓名",
          dataIndex: "name",
          key: "name",
          fixed: "left"
        },
        // {
        //   title: "性别",
        //   dataIndex: "gender",
        //   key: "gender"
        // },
        {
          title: "职位",
          dataIndex: "positionName",
          key: "positionName"
        },
        // {
        //   title: "身份证",
        //   dataIndex: "idCard",
        //   key: "idCard"
        // },
        // {
        //   title: "健康证日期",
        //   dataIndex: "healthCardDate",
        //   key: "healthCardDate"
        // },
        // {
        //   title: "健康证图片",
        //   dataIndex: "healthCardDate",
        //   key: "healthCardDate"
        // },
        {
          title: "联系方式",
          dataIndex: "phone",
          key: "phone"
        },
        {
          title: "邮箱",
          dataIndex: "email",
          key: "email"
        },
        {
          title: "操作",
          // dataIndex: "action",
          key: "action",
          scopedSlots: { customRender: "action" },
          fixed: "right",
          width: 150
        }
      ],
      findata: [],
      finqueryParam: {
        // tenantId : this.roleListDetail.id,
        // brandId : this.roleListDetail.brandId
      },
      queryParam: {
        name: "",
        cityName: "",
        brandName: "",
        facilityName: ""
      },
      paginationfin: defaultPagination(() => {}),
      paginationStaff: defaultPagination(() => {}),
      paginationcontractData: defaultPagination(() => {}),
      pagination: defaultPagination(() => {}),
      //表头
      contractColumns: [
        {
          title: "签订合同编号",
          // dataIndex: "action",
          key: "action",
          scopedSlots: { customRender: "action" },
          fixed: "left"
        },
        {
          title: "合同起止时间",
          colSpan: 2,
          dataIndex: "startDateLg",
          key: "startDateLg"
        },
        {
          title: "合同结束时间",
          colSpan: 0,
          dataIndex: "endDateLg",
          key: "endDateLg"
        },
        {
          title: "所属城市",
          dataIndex: "cityName",
          key: "cityName"
        },
        {
          title: "厨房品牌",
          dataIndex: "brandName",
          key: "brandName"
        },
        {
          title: "所属门店",
          dataIndex: "facilityName",
          key: "facilityName"
        },
        {
          title: "档口编号",
          dataIndex: "kitchenCode",
          key: "kitchenCode"
        },
        {
          title: "客户状态",
          dataIndex: "tenantStatus",
          key: "tenantStatus",
          fixed: "right",
          width: 100
        }
      ],
      //表头
      fincolumns: [
        {
          title: "账单编号",
          dataIndex: "code",
          key: "code",
          fixed: "left"
        },
        {
          title: "所属城市",
          dataIndex: "cityName",
          key: "cityName"
        },
        {
          title: "所属门店",
          dataIndex: "facilityName",
          key: "facilityName"
        },
        {
          title: "所属档口",
          dataIndex: "kitchenName",
          key: "kitchenName"
        },
        {
          title: "账单周期",
          // colSpan: 2,
          dataIndex: "cycleStartDateLg",
          key: "cycleStartDateLg",
          scopedSlots: { customRender: "cycleStartDateLg" }
        },
        // {
        //   title: "账单END",
        //   colSpan: 0,
        //   dataIndex: "cycleEndDateLg",
        //   key: "cycleEndDateLg"
        // },
        {
          title: "账单金额",
          dataIndex: "billAmount",
          key: "billAmount"
        },
        {
          title: "缴纳周期",
          // colSpan: 2,
          dataIndex: "payCycleStartDateLg",
          key: "payCycleStartDateLg",
          scopedSlots: { customRender: "payCycleStartDateLg" }
        },
        // {
        //   title: "缴纳END",
        //   colSpan: 0,
        //   dataIndex: "payCycleEndDateLg",
        //   key: "payCycleEndDateLg"
        // },
        {
          title: "滞纳金",
          dataIndex: "mustLateFee",
          key: "mustLateFee"
        },
        {
          title: "需缴费金额",
          dataIndex: "needPaymentAmount",
          key: "needPaymentAmount"
        },
        {
          title: "支付状态",
          dataIndex: "payStatus",
          key: "payStatus"
        },
        {
          title: "支付时间",
          dataIndex: "payDateLg",
          key: "payDateLg"
        },
        {
          title: "是否到账",
          dataIndex: "isArrivalAmount",
          key: "isArrivalAmount",
          fixed: "right",
          width: 100
        }
      ],
      tabListNoTitle: [
        {
          key: "baseDetail",
          tab: "基本信息"
        }
        //   {
        //   key: 'rentDetail',
        //   tab: '承租信息',
        // }, {
        //   key: 'licenceDetail',
        //   tab: '证照信息',
        // }, {
        //   key: "costDetail",
        //   tab: "费用信息",
        // }
      ],
      noTitleKey: "baseDetail",
      editvisible: false,
      record: "",
      kitstatus: "",
      detail: "",
      roleListEdit: "",
      editequipment: false,
      contractDetails: "",
    };
  },
  computed: {
    ...mapGetters("users", ["authenticatedUser"]),
    rolenewHasRight() {
      return hasPermissions(
        [OrganizationActions.STORE_CREATE],
        this.authenticatedUser.actions
      );
    },
    roleeditHasRight() {
      return hasPermissions(
        [OrganizationActions.STORE_EDIT],
        this.authenticatedUser.actions
      );
    },
    authnewHasRight() {
      return hasPermissions(
        [OrganizationActions.STORE_CREATE],
        this.authenticatedUser.actions
      );
    },
    autheditHasRight() {
      return hasPermissions(
        [OrganizationActions.STORE_EDIT],
        this.authenticatedUser.actions
      );
    },
    searchconHasRight() {
      return hasPermissions(
        [OrganizationActions.CONTRACT_READ],
        this.authenticatedUser.actions
      );
    },
    searchbillHasRight() {
      return hasPermissions(
        [OrganizationActions.BILL_READ],
        this.authenticatedUser.actions
      );
    }
  },
  created() {
    this.init(this.$route.query.roleListDetailId);
    this.storeStaffList();
    this.contractlist();
    this.billlist();
    this.staffList();
    Bus.$on("getTarget", target => {
      this.$router.push({
        path: "/role/RoleList"
      });
    });
    axios({
      url:   this.$baseUrl + "/department/listDepartmentSelect",
      withCredentials: true,
      headers: {
        Accept: "*/*"
      }
    }).then(res => {
      this.oneLevelDpt = res.data.rows;
    });
    this.selectStore();
  },
  filters: {
    showPositionId(val) {
      switch (val) {
        case 1:
          return "管理员";
        case 2:
          return "店长";
        case 3:
          return "员工";
      }
    }
  },
  methods: {
    moment,
    // 选择店铺名称接口
    selectStore() {
      axios({
        url:   this.$baseUrl + "/tenantStore/select",
        method: "GET",
        withCredentials: true,
        params: {
          tenantId: this.roleListDetailId
        }
      }).then(res => {
        if (res.data.rows) {
          this.storeList = res.data.rows;
        }
      });
    },
    onEdit() {
      // this.roleListEdit = this.roleListDetail;
      // this.editDetail = true;
      this.$router.push({
        path: "/role/RoleList/roleListEdit",
        query: { roleListEditId: this.roleListDetailId }
      });
    },
    cancelRole() {
      this.editDetail = false;
      this.init();
    },
    // 权限接口
    storeStaffList(current, pageSize) {
      // 权限列表
      axios({
        url:   this.$baseUrl + "/storeStaff/list",
        method: "GET",
        withCredentials: true,
        params: {
          rows: pageSize || 20,
          page: current || 1,
          tenantId: this.roleListDetailId
        }
      }).then(res => {
        if (res.data.rows) {
          this.devicedata = res.data.rows;
        }
      });
    },
    init() {
      axios({
        url:   this.$baseUrl + "/tenant/query",
        method: "GET",
        params: {
          id: this.roleListDetailId
        }
      }).then(res => {
        if (res.data.obj) {
          res.data.obj.cuisineType = this.cuisineTypeList(
            res.data.obj.cuisineType
          );
          res.data.obj.level = "-";
          res.data.obj.type =
            res.data.obj.type = "-";
          this.detail = res.data.obj;
        }
      });
    },
    cuisineTypeList(val) {
      // alert(val);
      if (val) {
        switch (val) {
          case 0:
            val = " ";
            break;
          case 1:
            val = "粤菜";
            break;
          case 2:
            val = "川湘菜";
            break;
          case 3:
            val = "江浙菜";
            break;
          case 4:
            val = "快餐便当";
            break;
          case 5:
            val = "简餐沙拉";
            break;
          case 6:
            val = "米粉面馆";
            break;
          case 7:
            val = "饺子馄饨";
            break;
          case 8:
            val = "包子粥店";
            break;
          case 9:
            val = "生煎锅贴";
            break;
          case 10:
            val = "小吃烧烤";
            break;
          case 11:
            val = "香锅干锅";
            break;
          case 12:
            val = "海鲜龙虾";
            break;
          case 13:
            val = "砂锅汤类";
            break;
          case 14:
            val = "火锅";
            break;
          case 15:
            val = "西餐";
            break;
          case 16:
            val = "东南亚菜";
            break;
          case 17:
            val = "日韩菜";
            break;
          case 18:
            val = "意面披萨";
            break;
          case 19:
            val = "汉堡薯条";
            break;
          case 20:
            val = "其他地方菜系";
            break;
          case 21:
            val = "甜品饮品";
            break;
          case 22:
            val = "果蔬生鲜";
            break;
          case 23:
            val = "鲜花绿植";
            break;
          case 24:
            val = "医药健康";
            break;
          case 25:
            val = "商品超市";
            break;
          case 26:
            val = "服装鞋帽";
            break;
          case 27:
            val = "美妆";
            break;
          default:
            val = "无";
        }
      }
      // alert(val);
      return val;
    },
    changePageSizeStaff(val) {
      this.staffList(val.current, val.pageSize);
    },
    staffList(current, pageSize) {
      axios({
        url:   this.$baseUrl + "/tenant/staff/list",
        method: "GET",
        withCredentials: true,
        params: {
          rows: pageSize || 20,
          page: current || 1,
          tenantId: this.roleListDetailId
        }
      }).then(res => {
        if (res.data.rows) {
          this.dataStaff = res.data.rows;
          const pagination = { ...this.paginationStaff };
          pagination.total = res.data.count;
          this.paginationStaff = pagination;
        }
      });
    },
    //合同信息
    changePageSizecontractData(val) {
      this.contractlist(val.current, val.pageSize);
    },
    contractlist(current, pageSize) {
      axios({
        url:   this.$baseUrl + "/contract/kitchen/list",
        method: "GET",
        params: {
          rows: pageSize || 20,
          page: current || 1,
          // rows: this.roleListDetail,
          tenantId: this.roleListDetailId,
          brandId: this.queryParam.brandId,
          cityName: this.queryParam.cityName,
          brandName: this.queryParam.brandName,
          facilityName: this.queryParam.facilityName
        }
      }).then(res => {
        if (res.data.rows) {
          res.data.rows.forEach(item => {
            item.startDateLg = this.changeTimeStyle(item.startDateLg);
            item.endDateLg = this.changeTimeStyle(item.endDateLg);
            this.brandListTenantStatus(item);
          });
          this.contractData = res.data.rows;
          const pagination = { ...this.paginationcontractData };
          pagination.total = res.data.count;
          this.paginationcontractData = pagination;
        } else {
          this.contractData = [];
        }
      });
    },
    brandListTenantStatus(val) {
      if (val.tenantStatus == null) {
        return;
      } else {
        switch (val.tenantStatus) {
          case 1:
            val.tenantStatus = "预定中";
            break;
          case 2:
            val.tenantStatus = "已预订";
            break;
          case 3:
            val.tenantStatus = "签约中";
            break;
          case 4:
            val.tenantStatus = "已签约";
            break;
          case 5:
            val.tenantStatus = "改造中";
            break;
          case 6:
            val.tenantStatus = "待入驻";
            break;
          case 7:
            val.tenantStatus = "已入驻";
            break;
          case 8:
            val.tenantStatus = "申请退租中";
            break;
          case 9:
            val.tenantStatus = "退租中";
            break;
          case 10:
            val.tenantStatus = "已退租";
            break;
          case 11:
            val.tenantStatus = "已离场";
            break;
          default:
            "无";
        }
      }
      return val.status;
    },
    changeTimeStyle(time) {
      return time ? moment(time).format("YYYY-MM-DD") : "";
    },
    //账单信息
    changePageSizefin(val) {
      this.billlist(val.current, val.pageSize);
    },
    billlist(current, pageSize) {
      axios({
        url:   this.$baseUrl + "/bill/relation/list",
        method: "GET",
        params: {
          rows: pageSize || 20,
          page: current || 1,
          // ...this.finqueryParam
          tenantId: this.roleListDetailId,
          brandId: this.$route.query.roleListBrandId
        }
      }).then(res => {
        if (res.data.rows) {
          res.data.rows.forEach(item => {
            item.cycleStartDateLg = this.changeTimeStyle(item.cycleStartDateLg);
            item.cycleEndDateLg = this.changeTimeStyle(item.cycleEndDateLg);
            item.payCycleStartDateLg = this.changeTimeStyle(
              item.payCycleStartDateLg
            );
            item.payCycleEndDateLg = this.changeTimeStyle(
              item.payCycleEndDateLg
            );
            item.payDateLg = this.changeTimeStyle(item.payDateLg);
            item.payStatus =
              item.payStatus == 1
                ? "未支付"
                : [item.payStatus == 2 ? "部分支付" : "已支付"];
            item.isArrivalAmount =
              item.isArrivalAmount == "Y" ? "已到账" : "未到账";
          });
          this.findata = res.data.rows;
          const pagination = { ...this.paginationfin };
          pagination.total = res.data.count;
          this.paginationfin = pagination;
        }
      });
    },
    //大事件信息
    // kitStatus() {
    //   axios({
    //     url:   this.$baseUrl + "/kitstatus/query",
    //     method: "GET",
    //     params: {
    //       kitchenId: this.roleListDetail.id
    //     }
    //   }).then(res => {
    //     this.kitstatus = res.data.obj;
    //   });
    // },
    //新增客户
    addStaff() {
      this.newvisible = true;
    },
    handleSubmitStaff() {
      const {
        form: { validateFields }
      } = this;
      validateFields((errors, values) => {
        if (!errors) {
          this.staffSave(values);
        }
      });
    },
    staffSave(values) {
      axios({
        url:   this.$baseUrl + "/tenant/staff/save",
        method: "POST",
        withCredentials: true,
        data: {
          ...values,
          tenantId: this.roleListDetailId
        }
      }).then(res => {
        if (res.data.success) {
          this.newvisible = false;
          this.confirmLoading = false;
          this.staffList();
          this.$message.success("客户已建立！");
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
      });
    },
    //新增权限
    newEquipment() {
      this.newequipment = true;
    },
    // 权限编辑
    handleEditEqu(val) {
      this.myEditEqu = val;
      this.changeEquipment = true;
    },
    // 权限删除
    handleDeleteEqu(val) {
      this.deleteEquipmentSubmit(val);
    },
    //新增权限的接口
    newequipmentSubmit() {
      const {
        form: { validateFields }
      } = this;
      this.confirmLoading = true;
      validateFields((errors, values) => {
        if (!errors) {
          axios({
            url:   this.$baseUrl + "/storeStaff/create",
            method: "POST",
            withCredentials: true,
            data: {
              ...values
            }
          }).then(res => {
            if (res.data.success) {
              notification.success({
                message: "权限已新增！",
                style: {
                  width: "600px",
                  marginLeft: `${235 - 500}px`,
                  background: "#f6ffed"
                }
              });
              this.storeStaffList();
            } else {
              notification.error({
                message: res.data.returnMsg,
                style: {
                  width: "600px",
                  marginLeft: `${235 - 500}px`,
                  background: "#f6ffed"
                }
              });
            }
          });

          this.newequipment = false;
          this.confirmLoading = false;
          this.$emit("ok", values);
        } else {
          // this.confirmLoading = false;
          this.newequipment = false;
          this.confirmLoading = false;
        }
      });
    },
    // 编辑权限的接口
    editEquipmentSubmit() {
      const {
        form: { validateFields }
      } = this;
      this.confirmLoading = true;
      validateFields((errors, values) => {
        if (!errors) {
          axios({
            url:   this.$baseUrl + "/storeStaff/modify",
            method: "POST",
            withCredentials: true,
            data: {
              ...values,
              id: this.myEditEqu.id,
              storeStaffRelationId: this.myEditEqu.storeStaffRelationId
            }
          }).then(res => {
            if (res.data.success) {
              notification.success({
                message: "权限已修改",
                style: {
                  width: "600px",
                  marginLeft: `${235 - 500}px`,
                  background: "#f6ffed"
                }
              });
              this.storeStaffList();
            } else {
              notification.error({
                message: res.data.returnMsg,
                style: {
                  width: "600px",
                  marginLeft: `${235 - 500}px`,
                  background: "#f6ffed"
                }
              });
            }
          });
          this.changeEquipment = false;
          this.confirmLoading = false;
          this.$emit("ok", values);
        } else {
          // this.confirmLoading = false;
          this.changeEquipment = false;
          this.confirmLoading = false;
        }
      });
    },
    // 删除权限的接口
    deleteEquipmentSubmit(val) {
      let that = this;
      modal.confirm({
        title: "确定要删除该权限吗?",
        content: "该操作无法撤回，你还要继续吗？",
        onOk() {
          axios({
            url:   that.$baseUrl + "/storeStaff/remove",
            method: "GET",
            withCredentials: true,
            params: {
              id: val.id,
              storeStaffRelationId: val.storeStaffRelationId
            }
          }).then(res => {
            if (res.data.success) {
              notification.success({
                message: "权限已删除",
                style: {
                  width: "600px",
                  marginLeft: `${235 - 500}px`,
                  background: "#f6ffed"
                }
              });
              that.storeStaffList();
              console.log(111);
            } else {
              notification.error({
                message: res.data.returnMsg,
                style: {
                  width: "600px",
                  marginLeft: `${235 - 500}px`,
                  background: "#f6ffed"
                }
              });
            }
          });
        }
      });
    },
    //编辑设备
    equipmentEdit(res) {
      this.record = res;
      this.editequipment = true;
    },
    editequipmentSubmit() {
      let that = this;
      const {
        form: { validateFields }
      } = this;
      this.confirmLoading = true;
      validateFields((errors, values) => {
        values.oneLevelDpt = that.oneLevelDpt[values.oneLevelDpt];
        if (!errors) {
          this.editequipment = false;
          this.confirmLoading = false;
          this.$emit("ok", values);
        } else {
          this.confirmLoading = false;
          this.editequipment = false;
        }
      });
    },
    //编辑客户
    handlestaffEdit(val) {
      this.record = val;
      this.editvisible = true;
    },
    handleEditStaff() {
      const {
        form: { validateFields }
      } = this;
      validateFields((errors, values) => {
        if (!errors) {
          this.staffModify(values);
        }
      });
    },
    staffModify(values) {
      axios({
        url:   this.$baseUrl + "/tenant/staff/modify",
        method: "POST",
        withCredentials: true,
        data: {
          id: this.record.id,
          ...values,
          tenantId: this.roleListDetailId
        }
      }).then(res => {
        if (res.data.success) {
          this.staffList();
          this.editvisible = false;
          this.confirmLoading = false;
          this.$message.success("客户已修改！");
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
      });
    },
    //删除客户
    staffDelete(val) {
      let that = this;
      modal.confirm({
        title: "确定要删除该客户吗?",
        content: "该操作无法撤回，你还要继续吗？",
        onOk() {
          axios({
            url:   that.$baseUrl + "/tenant/staff/remove",
            method: "GET",
            withCredentials: true,
            params: {
              id: val.id
            }
          }).then(() => {
            that.staffList();
          });
          return new Promise((resolve, reject) => {
            setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
          }).catch(() => console.log("Oops errors!"));
          // _this.staffDelete(record);
          // _this.init();
        }
      });
    },
    handleCancel() {
      this.newequipment = false;
      this.changeEquipment = false;
      this.newvisible = false;
      this.editvisible = false;
    },
    onTabChange(key, type) {
      console.log(key, type);
      this[type] = key;
    },
    selectContract() {
      this.contractlist();
    },
    selectBill() {
      this.billlist();
    },
    //合同详情
    handleDetails(val) {
      this.$router.push({
        path: "/role/contractList/contractDetail",
        query: { contractDetailsId: val.id }
      });
    },
    changeImgbg() {
      if (this.detail.coopStatus == "已合作") {
        return "open";
      } else if (this.detail.coopStatus == "未合作") {
        return "close";
      }
    }
  }
};
</script>

<style scoped>
.table-operator {
  margin-bottom: 18px;
}
.ant-col-md-8 {
  width: 25%;
}
</style>
